.iemsg-modal{
  width: 60vw;
  position: absolute;
  top: 10vh;
  margin: 15vh 20vw;
  padding: 2rem;
  text-align: center;
  font-weight: bold;
  z-index: 100001;
  color: #484848;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.7), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}